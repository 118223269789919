import ReactDOM from 'react-dom';
import App from './App';
// import React, { useState, useEffect } from 'react'
// import axios from 'axios'

// const promise = axios.get('https://restcountries.eu/rest/v2/all')
// console.log(promise)

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
